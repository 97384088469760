<template>
  <div>
    <v-menu
      bottom
      offset-y
      auto
      content-class="menu"
      v-if="visible"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="error"
          v-on="on"
          icon
          small
          class="mr-4"
        >
          <v-icon large>{{$icon('i.Error')}}</v-icon>
        </v-btn>
      </template>
      <v-card class="d-flex flex-column">
        <v-list
          class="flex-grow-1"
          v-if="importError"
        >
          <v-list-item link>
            <v-icon class="mx-4 error--text">{{$icon('i.Import')}}</v-icon>
            <v-list-item-content><span class="text-body-1">{{$t('t.ImportFailedMessage')}}</span></v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          class="flex-grow-1"
          v-if="computedHasEmailError"
        >
          <v-list-item
            link
            :key="index"
            v-for="(item ,index) in expiredItems"
          >
            <v-icon class="mx-4 error--text">{{$icon('i.Email')}}</v-icon>
            <v-list-item-content>
              <div class="d-flex align-center">
                <div class="d-flex flex-column">
                  <computed-document-ref
                    :id="item.providerId"
                    document-type='providers'
                  />
                  <span class="caption">{{$t('t.ApiKey')}} : {{item.name}} ({{item.providerType}})</span>
                </div>
                <v-btn
                  class="ml-8"
                  justify-center
                  color="primary"
                  @click="connect(item)"
                  v-ripple
                >
                  <span>{{$t('t.ExpiredReSignIn')}}</span>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list
          class="flex-grow-1"
          v-if="computedDaysBeforeExpirationVisible"
        >
          <v-list-item>
            <v-list-item-content>
              <span>{{$t('t.LicenseExpireInDays', { days: daysBeforeExpiration })}}</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref')
  },
  computed: {
    visible () {
      return this.computedHasEmailError || this.importError || this.computedDaysBeforeExpirationVisible
    },
    computedHasEmailError () {
      return this.expiredItems && this.expiredItems.length > 0
    },
    computedDaysBeforeExpirationVisible () {
      return this.daysBeforeExpiration <= 30
    }
  },
  beforeDestroy () {
    clearInterval(this.loopHandler)
  },
  mounted () {
    this.setLoop()
  },
  methods: {
    connect (item) {
      const redirect = encodeURIComponent(window.location)
      const url = `${window.location.protocol}//${window.location.host}/api/core/providers/${item.providerId}/${item.providerType}/authentication?redirect=${redirect}`
      window.location = url
    },
    setLoop () {
      this.getData()

      this.loopHandler = setInterval(async () => {
        this.getData()
      }, 180000) // 3 min
    },
    async getData () {
      const [r1, r2, r3] = await Promise.all([
        this.$http().get('/core/v6/email-expired-items'),
        this.$http().get('/importHistory?getLastImport=true'),
        this.$http().get('/core/v6/settings/license')
      ])
      this.expiredItems = r1?.data?.expiredItems ?? null
      // this.expiredItems = [{ businessDivisionId: '62111791-4049-A13B-8647-BEDB336B5DC2', businessDivisionName: 'Bob', apiKeyName: 'apikey xyz', apiKeyProviderType: 'Google' }]

      this.importError = !(r2?.data?.completed ?? true)
      this.daysBeforeExpiration = r3?.data?.daysBeforeExpiration ?? 9999
    }
  },
  data () {
    return {
      daysBeforeExpiration: 1000,
      loopHandler: undefined,
      expiredItems: null,
      importError: false
    }
  },
  props: {
  }
}
</script>

<style lang="stylus" scoped></style>
